<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Emails for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Use this page to configure, customize, and test the emails for this event. 
        </p>
      </v-card-text>      

        <v-list>
          <v-list-group  v-for="(section, sidx) in emails" :key="sidx" :prepend-icon="section.icon" no-action v-model="section.expanded">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="section.header"></v-list-item-title>
                <v-list-item-subtitle>{{section.emails.filter(x => x.visible && x.state).length}} out of {{section.emails.filter(x => x.visible).length}}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template v-for="(item, idx) in section.emails.filter(x => x.visible)">
              <v-divider v-if="idx >0" :key="idx + '_divider'"/>
              <v-list-item two-line :key="idx">
                <v-list-item-content>
                  <v-list-item-title>{{item.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{item.subtitle}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="item.state">
                  <v-btn text :color="item.content ? 'primary' : 'black'" @click="customizeEmail(item)"><v-icon small class="mr-2">fa-pencil</v-icon> Customize</v-btn>
                </v-list-item-action>
                <v-list-item-action v-if="item.state && item.test_url">
                  <v-btn text @click="sendTestEmail(item)"><v-icon small class="mr-2">fa-paper-plane</v-icon> Test</v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-switch v-model="item.state" :disabled="item.locked" hide-details class="mt-0 mp-0" @change="toggleEmail(item)"/>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>

      <MarkdownEditor v-if="editedItem" ref="editor" v-model="editedItem.content" :eventId="event.id" :placeholders="editedItem.placeholders" :description="editedItem.editorDescription" @input="saveContent" style="display:none;"/>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import MarkdownEditor from "@/components/MarkdownEditor";

export default {
  name: "Certificate",
  components: {
    Header,
    MarkdownEditor,
  },
  props: {
  },
  data() {
    return {
      event: null,
      siteData: siteData,
      editedItem: null,
      races: [],
      raceHeaders: [
        { text: 'Name', sortable: true, value: 'name',},
        { text: 'Status', sortable: false, value: 'status',},
        { text: 'Auto-generate (on completion)', sortable: false, value: 'generate',},
        { text: 'Send email', sortable: false, value: 'email',},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      badgeHeaders: [
        { text: 'Code', value: 'id', sortable: false },
        { text: 'Value', value: 'text', sortable: false },
        { text: 'Example', value: 'example', sortable: false},
      ],
      emails: [],
      eventEmails: [],
      raceEmails: [],
      badgeEmails: [],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      this.races = this.event.races || [];

      this.eventEmails = [
        {
          title: "Invite Email",
          subtitle: "Triggered when importing/syncing new registration data.",
          editorDescription: 'This custom content is included in the invite email and will replace the default introduction until the various join options. Please send a test email to verify the result.',
          content: this.event.invite_email_text,
          state: this.event.send_invite_email,
          test_url: `${id}/email/invite`,
          visible: true,
          placeholders: { 
            code: 'Personal verification code', 
            name: 'First name (when available) or otherwise full name (first and last name)', 
            given_name: 'Given name (first name)', 
            family_name: 'Family name (last name)', 
            team: 'Team name (for team leaderboards)', 
            join_url: 'Personal link to join event', 
            quickentry_url: 'Personal link for QuickEntry' 
          },
          onChangeState: x => this.eventUtil.event.send_invite_email = x.state,
          onCustomize: x => this.eventUtil.event.invite_email_text = x.content,
        },
        {
          title: "Join Confirmation Email",
          subtitle: "Triggered when a user joins a challenge.",
          editorDescription: 'This custom content is included in the join confirmation email and will replace the default introduction until the summary of key event and profile information. Please send a test email to verify the result.',
          content: this.event.join_confirmation_email_template,
          state: true,
          test_url: `${id}/email/confirmation`,
          visible: true,
          locked: true,
          placeholders: { 
            code: 'Personal verification code', 
            name: 'First name (when available) or otherwise full name (first and last name)', 
            given_name: 'Given name (first name)', 
            family_name: 'Family name (last name)', 
            getting_started_url: 'Link to page with how-to guides' 
          },
          onCustomize: x => this.eventUtil.event.join_confirmation_email_template = x.content,
        },
        {
          title: "Registration Confirmation Email",
          subtitle: "Triggered when a user registers for this event.",
          editorDescription: 'This custom content is included in the registration confirmation email and will replace the default introduction until the summary of key event and profile information. Please send a test email to verify the result.',
          content: this.event.reg_confirmation_email_template,
          state: true,
          test_url: `${id}/email/reg-confirmation`,
          visible: this.event.reg_config && this.event.reg_config.enabled,
          locked: true,
          placeholders: { 
            code: 'Personal verification code', 
            name: 'First name (when available) or otherwise full name (first and last name)', 
            given_name: 'Given name (first name)', 
            family_name: 'Family name (last name)', 
            fee: 'The total fee paid to participate in this event',
            getting_started_url: 'Link to page with how-to guides',
            instructions_url: 'Link to page with instructions on how to join this event' 
          },
          onCustomize: x => this.eventUtil.event.reg_confirmation_email_template = x.content,
        },
        {
          title: "Refer-a-Friend Email",
          subtitle: "Triggered when refer-a-friend is used for this specific leaderboard..",
          content: this.event.referral_email_template,
          state: true,
          locked: true,
          test_url: `${id}/email/friendinvite`,
          visible: this.event.referral_msg,
          onCustomize: x => this.eventUtil.event.referral_msg = x.content,
        },
        {
          title: "Bib Email",
          subtitle: "Triggered when a virtual bib is created.",
          content: this.event.bib_email_text,
          state: this.event.send_bib_email,
          test_url: `${id}/email/bib`,
          visible: this.event.bib_doc_id,
          onChangeState: x => this.eventUtil.event.send_bib_email = x.state,
          onCustomize: x => this.eventUtil.event.bib_email_text = x.content,
        },
      ];
      this.raceEmails = this.races.map(x => ({
        header: `Leaderboard ${x.name}`,
        icon: 'fal fa-trophy',
        expanded: false,
        emails: [
          {
            title: "Completion Email",
            subtitle: "Send on completion of the challenge goal. The email contains a Share option which helps to increase engagement of this challenge.",
            content: x.completed_email_text,
            state: x.send_completed_email,
            race_id: x.id,
            test_url: `${id}/${x.id}/email/completed`,
            visible: x.type !== 'EXTERNAL',
            placeholders: { 
              name: 'First name (when available) or otherwise full name (first and last name)', 
              given_name: 'Given name (first name)', 
              family_name: 'Family name (last name)', 
              team: 'Team name (for team leaderboards)', 
            },
            onChangeState: x => this.eventUtil.event.races.find(y => y.id == x.race_id).send_completed_email = x.state,
            onCustomize: x => this.eventUtil.event.races.find(y => y.id == x.race_id).completed_email_text = x.content,
          },
          {
            title: "Certificate Email",
            subtitle: "Triggered when a virtual bib is created.",
            content: x.certificate_email_text,
            state: x.send_certificate_email,
            race_id: x.id,
            test_url: `${id}/${x.id}/email/certificate`,
            visible: x.certificate_doc_id,
            onChangeState: x => this.eventUtil.event.races.find(y => y.id == x.race_id).send_certificate_email = x.state,
            onCustomize: x => this.eventUtil.event.races.find(y => y.id == x.race_id).certificate_email_text = x.content,
          },
          {
            title: "Refer-a-Friend Email",
            subtitle: "Triggered when refer-a-friend is used for this specific leaderboard..",
            content: x.referral_email_template,
            state: true,
            locked: true,
            race_id: x.id,
            test_url: `${id}/${x.id}/email/friendinvite`,
            visible: this.event.referral_msg,
            onCustomize: x => this.eventUtil.event.races.find(y => y.id == x.race_id).certificate_email_text = x.content,
          },
        ]
      }));



// badges:
//          test_url: `${id}/{raceId}/badges/{badgeId}/email`,


      this.emails = [
        { header: 'Event Emails', emails: this.eventEmails, icon: 'fal fa-home', expanded: true, },
        ...this.raceEmails,
      ];

      for (var race of this.event.races) {
        const response = await eventManagerService.getBadges(id, race.id);
        if (response.data.data.length > 0) {
          var badgeEmails = response.data.data.map(x => ({
              title: `Badge: ${x.name}` ,
              subtitle: `Triggered when this badge is rewarded to a user (for the first time)`,
              content: x.email_template,
              state: x.send_email,
              race_id: race.id,
              badge_id: x.id,
              test_url: `${id}/${race.id}/badges/${x.id}/email`,
              visible: true,
              onChangeState: x => this.saveBadge(x),
              onCustomize: x => this.saveBadge(x),
          }));

          this.emails = [ 
            ...this.emails, 
            {
              header: `Badges for ${race.name}`,
              icon: 'fal fa-shield',
              expanded: false,
              emails: badgeEmails,
            }
          ];
        }
      }
    },

    async saveBadge(item) {
      const response = await eventManagerService.getBadges(this.event.id, item.race_id);
      const badge = response.data.data.find(x => x.id == item.badge_id);
      badge.email_template = item.content;
      badge.send_email = item.state;
      var saveResponse = await eventManagerService.putBadge(this.event.id, item.race_id, badge);
      this.$helpers.toastResponse(this, saveResponse.data, 'Badge saved successfully.');
      console.log('BADGE', badge.id, item.title, item.badge_id, item.state, item.content);

    },
    async customizeEmail(item) {
      console.log('CONTENT', item.title, item.content);
      this.editedItem = item;
      await this.$nextTick();
      this.$refs.editor.openEditor();
    },

    async saveContent() {
      console.log('SAVE CONTENT', this.editedItem.title, this.editedItem.content);
      if (this.editedItem.onCustomize) {
        if (!this.editedItem.badge_id) await this.eventUtil.refresh();
        this.editedItem.onCustomize(this.editedItem);
        if (!this.editedItem.badge_id) await this.eventUtil.requestSave();
      }
    },

    async toggleEmail(item) {
      console.log('TOGGLE', item.title, item.state);
      if (item.onChangeState) {
        if (!item.badge_id) await this.eventUtil.refresh();
        item.onChangeState(item);
        if (!item.badge_id) await this.eventUtil.requestSave();
      }
    },

    async sendTestEmail(item) {
      if (item.test_url) {
        const response = await eventManagerService.sendTestEmail(item.test_url);
        this.$helpers.toastResponse(this, response.data, 'The test email is on its way! Please check your inbox in a few minutes from now.');
      }
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },


  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event);
    },

    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'CertifEmailsicate', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

